import React from 'react';
import { Link, graphql } from 'gatsby';

import IndexLayout from 'layouts/IndexLayout';
import Button from 'components/common/Button';
import { DarkScheme } from 'helpers/navColors.js';
import { Albums, Guides, SEO } from 'components';
import { InnerWrap, HeaderWrap } from 'components/common/Containers/styled';

const ExploreIndex = ({ data }) => {
  const { allStrapiGenreGuides, allStrapiAlbums } = data;

  return (
    <IndexLayout title='Explore' navColor={DarkScheme}>
      <SEO title='Explore Japanese music' />
      <InnerWrap>
        <HeaderWrap style={{ marginTop: 0 }}>
          <h3>Genre Guides</h3>
          <Link to='/guides'>
            <Button>View All</Button>
          </Link>
        </HeaderWrap>
        <Guides {...allStrapiGenreGuides} isPreview={true} />
        {/* <HeaderWrap>
          <h3>Classic Albums</h3>
          <Link to='/guides'>
            <Button>View All</Button>
          </Link>
        </HeaderWrap>
        <Albums {...allStrapiAlbums} /> */}
      </InnerWrap>
    </IndexLayout>
  );
};

export const query = graphql`
  query {
    allStrapiGenreGuides(
      sort: { fields: publishedDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          coverImage {
            url
          }
          albumRecommendations {
            albumName
            artistName
            coverImage {
              url
            }
          }
        }
      }
    }
    allStrapiAlbums(sort: { fields: releaseDate, order: DESC }, limit: 8) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
  }
`;

export default ExploreIndex;
